<template>
  <div class="ca__cv">
    <br><br>
    <center><h2>مساءلة معلم</h2></center>
    <div class="g" style="padding-right: 20px">
      <h3 class="text-center"> 
        المكرم/ {{ teacher.name }}
        وفقه الله &nbsp;&nbsp;
        السلام عليكم ورحمة الله وبركاته وبعد ،،،
        <br /><br />
        انه في يوم {{ day }} الموافق {{date}} اتضح ما يلي:
      </h3>
      <h3>
        <br>
        {{ types[type-1] }} &nbsp; &nbsp; ....................................................................
        <br><br>
      </h3>
      <h3>
        عليه نأمل توضيح اسباب ذلك مع ارفاق ما يؤيد عذركم ٫٫٫ ولكم تحياتي..
      </h3> <br />
      <h3>
        مدير المدرسة:
        <input
          type="text"
          :value="info.admin"
          style="border: 1px solid #ddd; width: 300px"
        />
        التوقيع &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; التاريخ
        {{ date }}
        <br />
      </h3>
      <hr />
      <h3>
        المكرم/ مدير المدرسة
        <input
          type="text"
          :value="info.admin"
          style="border: 1px solid #ddd; width: 300px"
        />
        وفقه الله <br /><br />
        السلام عليكم ورحمة الله وبركاته وبعد:
        <br />
        افيدكم ان اسباب ذلك ما يلي:
        <br /><br />
        ....................................................................
        ....................................................................
        ....................................................................
        <br />
        ....................................................................
        ....................................................................
        ....................................................................
        <br /><br />
        الاسم: {{ teacher.name }} &nbsp;&nbsp;&nbsp; التوقيع:
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; التاريخ
        {{ date }}
      </h3>
      <br />
      <h3>
        رأي مدير المدرسة: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; عذره مقبول
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; عذره غير مقبول ويحسم عليه
      </h3>
      <br />
      <h3>
        مدير المدرسة:
        <input
          type="text"
          :value="info.admin"
          style="border: 1px solid #ddd; width: 300px"
        />
        التوقيع &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; التاريخ
        {{ date }}
        <br />
      </h3>
      <br />
      <h3>
        ملاحظة: ترفق بطاقة المساءلة مع أصل القرار في حالة عدم قبول العذر لحفظها
        بملفه بالإدارة، وأصله لملفه بالمدرسة.
      </h3>
    </div>
    <div class="options text-center" ref="options" id="options">
      <div class="card">
        <div class="card-header">
          <h3 class="text-center c">خيارات</h3>
        </div>
        <div class="card-footer">
          <button
            class="btn btn-success text-left btn-block btn-lg"
            @click="save()"
            ref="savea"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M18.397 17.899l1.019 6.101-7.416-4.554-7.416 4.554 1.48-8.866-6.064-5.828 8.332-1.15 3.668-8.156 3.047 6.773c-1.258 1.186-2.047 2.863-2.047 4.727 0 3.213 2.334 5.875 5.397 6.399zm5.603-6.399c0 2.485-2.018 4.5-4.5 4.5-2.484 0-4.5-2.015-4.5-4.5s2.016-4.5 4.5-4.5c2.482 0 4.5 2.015 4.5 4.5zm-2-.5h-2v-2h-1v1.999l-2 .001v1h2v2h1v-2h2v-1z"
              />
            </svg>
            حفظ في حساب المعلم
          </button>
          <button
            class="btn btn-primary btn-block btn-lg text-left"
            @click="print()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M16 18h-8v-1h8v1zm-2 1h-6v1h6v-1zm10-14v13h-4v6h-16v-6h-4v-13h4v-5h16v5h4zm-18 0h12v-3h-12v3zm12 10h-12v7h12v-7zm4-8h-20v9h2v-3h16v3h2v-9zm-1.5 1c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5z"
              />
            </svg>
            طباعة الصفحة
          </button>
          <button
            v-b-modal.modal-1
            @click="messageFormat()"
            class="btn btn-secondary text-left btn-block btn-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M0 12l11 3.1 7-8.1-8.156 5.672-4.312-1.202 15.362-7.68-3.974 14.57-3.75-3.339-2.17 2.925v-.769l-2-.56v7.383l4.473-6.031 4.527 4.031 6-22z"
              />
            </svg>
            إرسال تنبيه للمعلم
          </button>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" title="إرسال تنبيه للمعلم بالمساءلة" hide-footer>
      <b-card-text>
        <div
          class="alert alert-danger text-center"
          style="padding: 20px"
          v-if="!info.sms_portal"
        >
          برجاء ضبط إعدادات الرسائل من خلال "الإعدادات العامة"
        </div>
        <div v-if="info.sms_portal">
          <div class="form-group">
            <h4 for="">الرسالة</h4>
            <textarea
              class="form-control"
              ref="message"
              rows="3"
              placeholder="قم بكتابة الرسالة هنا.."
            ></textarea>
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-primary" @click="sendNow()">
              إرسال الرسالة SMS
            </button>
          </div>
          <br />
          <hr />
          <br />
          <div class="col-12 text-center">
            <button class="btn btn-success" @click="sendNowWA()">
              إرسال الرسالة واتسآب
            </button>
            <br /><br />
            <small>
              برجاء التأكد من تثبيت اداة الإرسال
              <a
                href="https://chrome.google.com/webstore/detail/%D8%AE%D8%AF%D9%85%D8%A7%D8%AA-%D8%A7%D8%B6%D8%A7%D9%81%D9%8A%D8%A9-%D8%A8%D8%B1%D9%85%D8%AC%D8%A9-%D8%AF%D9%88%D8%AA-%D9%83%D9%88/klbkaeaebhjkhmhilmbkfdcjdlinjpco"
                target="_blank"
                >من هنا</a
              >
            </small>
          </div>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
const hijri = require("hijri-js");
const hdate = hijri.initialize();
const axios = require("axios");
import { VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data() {
    return {
      date: window.location.pathname.split("/")[3],
      hdate: null,
      teacher: {},
      id: null,
      ask_id: null,
      mdate: null,
      day: null,
      info: {},
      types: [
        "عدم تواجد داخل الفصل",
        "خروجك من الحصة قبل انتهاء الوقت",
        "عدم تحضير الدروس",
        "عدم المناوبة في الفسحة",
        "عدم المناوبة في الممرات",
        "عدم المناوبة في نهاية الدوام",
        "عدم الحضور لحصص الانتظار"
      ],
      type: 0
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  created() {
    var days = [
      "الأحد",
      "الاثنين",
      "الثلاثاء",
      "الاربعاء",
      "الخميس",
      "الجمعة",
      "السبت",
    ];
    var d = new Date(this.date);
    this.mdate = this.date
    this.day = days[d.getDay()];
    this.date = hdate.toHijri(
      this.date.split("-")[2] +
        "-" +
        this.date.split("-")[1] +
        "-" +
        this.date.split("-")[0],
      "-"
    );
    this.date =
      this.date.year + "/" + this.date.month + "/" + this.date.day + "هـ";
    this.id = window.location.pathname.split("/")[2];
    this.type = window.location.pathname.split("/")[4];
    var _g = this;
    axios
      .post(localStorage.getItem("api") + "/teachers/teacher", {
        jwt: localStorage.getItem("jwt"),
        id: this.id,
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.teacher = r.data.response;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
    var _g = this;
    axios
      .post(localStorage.getItem("api") + "/teachers/info", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.info = r.data.response;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    messageFormat() {
      var g = this;
      setTimeout(() => {
        if (g.$refs.message.value.trim() == "") {
          g.$refs.message.value = `المكرم/ ${g.teacher.name} وفقه الله
السلام عليكم ورحمة الله وبركاته وبعد ،،،
لديكم مساءلة جديدة بعنوان ${g.types[g.type-1]}`
        }
      }, 100);
    },
    print() {
      
      document.getElementById('options').style.display = "none";
      setTimeout(()=>{
window.print();
      setTimeout(()=>{
      document.getElementById('options').style.display = "block";
      },6000)
      },500)
    },
    save() {
      var _g = this;
      axios
        .post(localStorage.getItem("api") + "/teachers/ask", {
          jwt: localStorage.getItem("jwt"),
          number: this.teacher.number,
          id: this.teacher._id,
          date: this.mdate,
          type: this.type
        })
        .then(function (r) {
          if (r.data.status == 100) {
            _g.$refs.savea.innerHTML = "تم الحفظ بنجاح!";
            _g.$refs.savea.disabled = "disabled";
          } else {
            alert("حدث خطأ.");
          }
        })
        .catch(function () {
          alert("حدث خطأ..");
        });
    },
    sendNow() {
      var _g = this;
      if (
        confirm(
          `متأكد من إرسال الرسالة للرقم ${this.teacher.phone}\nمن البوابة ${this.info.sms_portal}؟`
        )
      ) {
        axios
          .post(localStorage.getItem("api") + "/teachers/send", {
            jwt: localStorage.getItem("jwt"),
            data: JSON.stringify([
              {
                phone: this.teacher.phone,
                message: this.$refs.message.value,
              },
            ]),
          })
          .then(function (r) {
            if (r.data.status == 100) {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تم إرسال الرسالة بنجاح",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "فشل إرسال الرسالة",
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    sendNowWA() {
      document.getElementById("brmja-messages-table").innerHTML = `
      <tr>
      <td>${this.teacher.phone}</td>
      <td><textarea>${this.$refs.message.value}</textarea></td>
      </tr>
      `;
      document.getElementById("brmja-send").click();
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@500&display=swap");
* {
  font-family: "Baloo Bhaijaan 2", cursive !important;
}
.fullInput {
  height: 50px;
  font-size: 18px;
  background: #e5efe6;
  border: 1px solid #497458;
}
.w {
  width: 100%;
}
.a {
  background-color: #497458 !important;
}
.a * {
  color: white !important;
}
.fullInputTH {
  padding: 0px;
}
.options {
  position: fixed;
  bottom: 10px;
  right: 60px;
}
.options .card {
  border: 2px solid red;
  box-shadow: 4px 3px 18px -4px rgba(0, 0, 0, 0.75);
}
</style>